.dropdown-toggle::after {
  display: none !important;
}

.anticon svg {
  color: #4b5675;
}

.ant-picker-input input {
  color: #ffffff !important;
}

.ant-picker-input input::placeholder {
  color: #4b5675 !important;
}